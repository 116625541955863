.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: slideIn 400ms ease-in-out forwards 350ms;
  // row-gap: 24px;
  height: calc(100vh - 32px);

  @media screen and (min-height: 800px) {
    height: unset;
  }
}

.actionBar {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  gap: 12px;

  opacity: 0;
  animation: slideDown 200ms ease-in-out forwards 1000ms;
}

.closeBtn {
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  background-color: #f4f4f4;
  padding: 8px 24px 7px;
  border-radius: 4px;
  box-shadow: #00000029 0px 3px 6px;
  margin-left: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  span {
    transition: color 0.2s ease-in-out;
    font-family: AppleSDGothicNeo-Medium;
    color: var(--buttonLabel);
    font-size: 15px;
    line-height: 22px;
  }

  &:active {
    opacity: .8;
    background-color: var(--readbuttonBackground);
    span {
      color: #f4f4f4;
    }
  }
}

.icon {
  display: block;

  g,
  path {
    transition: stroke 0.2s ease-in-out;
  }

  &:active {
    g,
    path {
      stroke: #00acee;
    }
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (hover: hover) {
  .closeBtn {
    &:hover {
      background-color: var(--readbuttonBackground);
      span {
        color: #f4f4f4;
      }
    }
  }
}

@media screen and (min-width: 674px) {
  .closeBtn {
    margin-top: 36px;
  }
  .actionBar {
    // margin-top: 48px;
    justify-content: center;
  }

  .icon {
    display: none;
  }
}
